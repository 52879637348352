<i18n>
{
  "en": {
    "title": "Export",
    "packName": "Name",
    "packNamePlaceholder": "Name",
		"sensorsSelected": "sensors selected",
		"format": "Format",
    "exportBtn": "Export Data"
  },
  "it": {
    "title": "Esporta",
    "packName": "Nome",
    "packNamePlaceholder": "Nome",
		"sensorsSelected": "sensori selezionati",
		"format": "Formato",
    "exportBtn": "Esporta"


  }
}
</i18n>

<template>
  <div class="main-container">
    <header>
      <move-text>{{ $t("title").toUpperCase() }}</move-text>
    </header>
    <form @submit.prevent="onSubmit" class="form-container">
      <div class="form-content">
        <div class="form-item">
          <label for="packname"
            ><move-text paragraph>{{ $t("packName") }}:</move-text></label
          >
          <move-input
            id="packname"
            type="text"
            name="packname"
            :value="packName"
            @input="onChangePackName"
            :placeholder="this.$t('packNamePlaceholder')"
          ></move-input>
        </div>
        <div class="form-item">
          <label for="infolabel">
            <move-text paragraph>{{sensorsSelected.length}} {{$t("sensorsSelected")}}</move-text>
          </label>
        </div>
        <div class="form-item">
          <label for="formatoption"
            ><move-text paragraph>{{ $t("format") }}:</move-text></label
          >
          <move-select
            id="formatoption"
            name="formatoption"
            :options="exportDataOptions"
            :value="formatSelected"
            @input="onChangeSelect"
            required
          ></move-select>
        </div>
      </div>
      <div class="form-submit">
        <div class="submit-item">
          <move-btn
            :disabled="disableSubmit"
            type="sumbit"
            large
            outlined
            rounded
            primary
            ><font-awesome-icon class="icon-padding" icon="fa-solid fa-file-arrow-down" /> {{ $t("exportBtn").toUpperCase() }}</move-btn
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "ResumeAndSubmit",
  props: {
    disableSubmit: Boolean,
    exportDataOptions: Array,
    formatSelected: String,
    packName: String,
		sensorsSelected: Array
  },
  methods: {
    onSubmit() {
      this.$emit("submit");
    },
    onChangeSelect(format) {
      this.$emit("changeFormat", { data: format });
    },
    onChangePackName(evt) {
      this.$emit("changePackName", { data: evt.target.value });
    },
  },
};
</script>

<style></style>

<style scoped>

.icon-padding{
  padding-right: 4px;
}

.main-container header {
  margin-bottom: 16px;
}

.main-container {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.main-content {
  width: 100%;
}

.form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-item {
  margin-bottom: 12px;
}

.form-submit {
  align-self: center;
}
</style>
