<i18n>
{
  "en": {
    "title": "Time Range",
    "startDate": "Start date",
    "endDate": "End date"
  },
  "it": {
    "title": "Range Temporale",
    "startDate": "Data di inizio",
    "endDate": "Data di fine"
  }
}
</i18n>

<template>
  <div class="main-container scroll">
    <header>
      <move-text>{{ $t("title").toUpperCase() }}</move-text>
    </header>
    <div class="content-container">
      <div class="dp-inpt-container">
        <div class="dp-inpt-item">
          <move-text>{{ $t("startDate") }}</move-text>
          <move-input
            disabled
            type="text"
            placeholder="dd/mm/yyyy"
            :color="this.dateValid ? 'white' : 'red'"
            :value="dateStart"
            ><font-awesome-icon icon="fa-solid fa-calendar"
          /></move-input>
        </div>
        <div class="dp-inpt-item">
          <move-text>{{ $t("endDate") }}</move-text>
          <move-input
            disabled
            type="text"
            placeholder="dd/mm/yyyy"
            :color="this.dateValid ? 'white' : 'red'"
            :value="dateEnd"
            ><font-awesome-icon icon="fa-solid fa-calendar"
          /></move-input>
        </div>
      </div>
      <div class="calendar-container">
        <move-date-picker
          class="dp-style"
          :dateRange="dateRange"
          v-on="$listeners"
          v-bind="$attrs"
          mode="date"
          @rangeSelected="onDateRangeSelect"
          is-range
          :columns="$screens({ default: 2, lg: 2 })"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dates from "../../../../helpers/dates";
export default {
  name: "DateRangeSelection",
  props: {
    dateRange: Array,
  },
  data() {
    return {
      dateStart: dates.toDateStringFormat(this.dateRange[0], { divider: "/" }),
      dateEnd: dates.toDateStringFormat(this.dateRange[1], { divider: "/" }),
      dateValid: true
    };
  },
  methods: {
    onDateRangeSelect(data) {
      this.dateStart = dates.toDateStringFormat(data.range[0], { divider: "/" });
      this.dateEnd = dates.toDateStringFormat(data.range[1], { divider: "/" });
      this.dateValid = data.valid;
    }
  }
};
</script>

<style scoped>

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.dp-inpt-item {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 4px;
}
.dp-inpt-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: space-evenly;
}
.main-container {
  overflow-x: auto;
  margin-bottom: 8px;
}

.main-container header {
  margin-bottom: 16px;
}

.calendar-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.info-container {
  min-width: var(--date-picker-info-container-max-width);
  margin-left: 24px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-left: 2px solid var(--secondary-color-light-2);
  padding-left: 24px;
}
.info-container .point {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  word-break: break-all;
}
</style>
