<template>
  <div class="grid-container scroll">
    <MoveCard rounded bordered class="max-heigth text-alignment">
      <SensorsSelection :sensors="sensors" @changeSensors="onChangeSensorsSelected"/>
    </MoveCard>
    <MoveCard rounded bordered class="max-heigth text-alignment">
      <DateRangeSelection
        :dateRange="dateRange"
        v-bind="$attrs"
        v-on="$listeners"
        @rangeSelected="onDateRangeSelect"
      />
    </MoveCard>
    <MoveCard rounded bordered class="max-heigth text-alignment">
      <ResumeAndSubmit
        :packName="packName"
        :exportDataOptions="exportDataOptions"
        :formatSelected="formatSelected"
        :disableSubmit="disableSubmit"
        :sensorsSelected="sensorsSelected"
        @submit="onSubmit"
        @changeFormat="onChangeFormat"
        @changePackName="onChangePackName"
      />
    </MoveCard>
  </div>
</template>

<script>
import ResumeAndSubmit from "./selection/ResumeAndSubmit.vue";
import SensorsSelection from "./selection/SensorsSelection.vue";
import DateRangeSelection from "./selection/DateRangeSelection.vue";

export default {
  name: "Selection",
  components: {
    SensorsSelection,
    DateRangeSelection,
    ResumeAndSubmit,
  },
  props: {
    devices: Array,
    sensors: Array,
    selectedRow: Object,
    processOnGoing: Boolean
  },
  data() {
    return {
      //last week
      dateRange: [new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), new Date()],
      rangeValid: true,
      exportDataOptions: ["csv", "json"],
      packName: "move_data",
      formatSelected: "json",
      sensorsSelected: [],
    };
  },
  computed: {
    areSensorsSelected(){
      return this.sensorsSelected.length ? true: false;
    },
    disableSubmit(){
      return !this.areSensorsSelected || this.packName === '' || this.formatSelected === '' || !this.rangeValid || this.processOnGoing
    }
  },
  watch:{
    selectedRow: {
      deep: true,
      handler: function() {
        this.dateRange = this.selectedRow.options.dateRange;
      }
    }
  },
  methods: {
    onDateRangeSelect(date = {range: [new Date(), new Date()], valid: false}) {
      this.dateRange = date.range;
      this.rangeValid = date.valid;
    },
    onChangeSensorsSelected(sensors){
      this.sensorsSelected = sensors
    },
    onChangeFormat({ data = "" } = {}) {
      this.formatSelected = data;
    },
    onChangePackName({ data = "" } = {}){
      this.packName = data
    },
    onSubmit(){
      const eui = this.sensorsSelected.map(_ => _.eui);
      this.$emit("submitExportData", {packName: this.packName, format: this.formatSelected, sensors: eui, dateRange: this.dateRange})
    },
  },
};
</script>

<style>
.text-alignment {
  text-align: left;
}
.grid-container {
  display: grid;
  grid-template-columns: minmax(600px, 6fr) minmax(400px, 4fr) minmax(
      200px,
      2fr
    );
  column-gap: 8px;
  overflow-x: auto;
}

.max-heigth {
  height: 500px;
  max-height: 400px;
}
</style>
