<i18n>
{
  "en": {
    "emptyText": "No sensors selected"
  },
  "it": {
    "emptyText": "Nessun sensore selezionato"
  }
}
</i18n>

<template>
  <div class="main-conteiner">
    <img
      class="img-no-data"
      src="../../../../../../public/assets/noData.svg"
      alt="noData"
    />
    <move-text class="text">{{ $t("emptyText") }}</move-text>
  </div>
</template>

<script>
export default {
  name: "EmptySensor"
};
</script>

<style scoped>
.main-conteiner {
  display: grid;
  height: 100%;
}
.img-no-data {
  width: 100px;
  opacity: 0.6;
  align-self: center;
  justify-self: center;
}

.text {
  text-align: center;
}
</style>
