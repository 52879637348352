<i18n>
{
  "en": {
    "title": "Exported data packages",
    "searchInputPlaceHolder": "Search",
    "packName": "Name",
    "dataExport": "Data Export",
    "typoDevice": "Typo Device",
    "deviceNumber": "Sensors",
    "dataTimeInterval": "Data Time Interval",
    "format": "Format",
    "state": "State"

  },
  "it": {
    "title": "Dati Esportati",
    "searchInputPlaceHolder": "Ricerca",
    "packName": "Nome",
    "dataExport": "Data",
    "typoDevice": "Tipo",
    "deviceNumber": "Sensori",
    "dataTimeInterval": "Range Temporale",
    "format": "Formato",
    "state": "Stato"
  }
}
</i18n>

<template>
  <MoveCard bordered rounded class="text-alignment">
    <div class="main-container">
      <header>
        <move-text>{{ $t("title") }}</move-text>
      </header>
      <HistoryEmpty v-if="!rowsInThisPage.length" />
      <div v-else class="table-history">
        <div class="section header-actions">
          <move-input
            @input="onChangeSearchInput"
            :placeholder="$t('searchInputPlaceHolder')"
          >
            <font-awesome-icon type="text" icon="fa-magnifying-glass" />
          </move-input>
        </div>
        <div class="section table">
          <div class="table-container scroll">
            <table class="table">
              <!-- header -->
              <thead>
                <tr>
                  <th
                    v-for="(itemH, key, iH) in table.header.elems"
                    :key="'thead_th' + iH"
                  >
                    <div class="th-container">
                      <move-text noWrap>{{ itemH.toUpperCase() }}</move-text>
                    </div>
                  </th>
                </tr>
              </thead>

              <!-- body -->
              <tbody>
                <tr
                  v-for="(itemR, iR) in rowsInThisPage"
                  :key="'tbody_tr' + iR"
                  :class="['tbody-tr']"
                >
                  <td
                    v-for="(itemD, key, iD) in itemR.elems"
                    :key="'tbody_td' + iR + iD"
                  >
                    <div class="td-container scroll">
                      <!-- other elems -->
                      <template>
                        <div v-if="key == 'packName'" class="packNameContainer">
                          <move-text noWrap>{{ itemD }}</move-text>
                        </div>

                        <div
                          v-else-if="key == 'typoDevice'"
                          class="typoDeviceContainer"
                        >
                          <move-text noWrap>{{ itemD }}</move-text>
                        </div>

                        <div
                          v-else-if="key == 'dataTimeInterval'"
                          class="dataTimeIntervalContainer"
                        >
                          <move-text noWrap>{{ itemD }}</move-text>
                        </div>

                        <!-- if state elem add progress bar elem and actions elem-->
                        <div v-else-if="key == 'state'" class="state-content">
                          <!-- progress bar -->
                          <div v-if="itemR.options.exported">
                            <move-text noWrap>Exported</move-text>
                          </div>
                          <div v-else class="progrss-bar-container">
                            <move-text noWrap>{{ itemD }}%</move-text>
                            <MoveProgressBar
                              class="progress-bar"
                              :progress="itemD"
                            ></MoveProgressBar>
                          </div>
                          <!-- actions -->
                          <move-btn
                            noWrap
                            icon
                            class="actions-container"
                            v-popover.left="{ name: 'popup' + iR }"
                          >
                            <font-awesome-icon icon="fa-solid fa-ellipsis" />
                          </move-btn>
                          <move-pop-up
                            :ref="'popupREF' + iR"
                            :name="'popup' + iR"
                            class="popover-container"
                          >
                            <move-btn
                              noWrap
                              text
                              block
                              class="popover-item"
                              @click="onClickDelete(itemR, 'popupREF' + iR)"
                            >
                              <font-awesome-icon
                                icon="fa-solid fa-trash"
                                class="popover-icon"
                              />Delete</move-btn
                            >
                          </move-pop-up>
                        </div>

                        <!-- normal text elem -->
                        <move-text v-else noWrap>{{ itemD }}</move-text>
                      </template>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- pagination -->
          <div class="pagination">
            <move-text class="page-indicator"
              >{{ this.page + 1 }} of {{ this.maxPage }}</move-text
            >
            <move-btn :disabled="page <= 0" @click="onClickPrev" icon
              ><font-awesome-icon icon="fa-solid fa-angle-left"
            /></move-btn>
            <move-btn :disabled="page >= maxPage - 1" @click="onClickNext" icon
              ><font-awesome-icon icon="fa-solid fa-angle-right"
            /></move-btn>
          </div>
        </div>
      </div>
    </div>
  </MoveCard>
</template>

<script>
import HistoryEmpty from "./history-section/HistoryEmpty.vue";
import dates from "../../../helpers/dates";
export default {
  name: "History",
  components: { HistoryEmpty },
  props: {
    sensors: Array,
    dpState: Array,
    processOnGoing: Boolean
  },
  data() {
    return {
      rowsPerPage: 7,
      page: 0,
      rowsFilteredBySearch: [],
      strSearch: "",
      table: {
        header: {
          elems: {
            packName: this.$t("packName"),
            dataExport: this.$t("dataExport"),
            typoDevice: this.$t("typoDevice"),
            deviceNumber: this.$t("deviceNumber"),
            dataTimeInterval: this.$t("dataTimeInterval"),
            format: this.$t("format"),
            state: this.$t("state")
          }
        },
        rows: []
      }
    };
  },
  watch: {
    dpState: {
      deep: true,
      handler: function(newDpState) {
        this.updateHistory(newDpState);
      }
    }
  },
  computed: {
    rowsToDisplay() {
      let rowsToDisplay = this.table.rows;
      this.filterElems();
      rowsToDisplay = this.rowsFilteredBySearch.length
        ? this.rowsFilteredBySearch
        : this.table.rows;
      return rowsToDisplay;
    },

    //build the rows per page
    rowsInThisPage() {
      const totItmes = this.rowsToDisplay.length;
      const tbRows = [];
      for (
        let i = 0;
        i < this.rowsPerPage && i + this.rowsPerPage * this.page < totItmes;
        i++
      ) {
        tbRows.push(this.rowsToDisplay[i + this.rowsPerPage * this.page]);
      }
      return tbRows;
    },
    //calculate the pax page of pagination
    maxPage() {
      const totItmes = this.rowsToDisplay.length;
      return Math.ceil(totItmes / this.rowsPerPage);
    }
  },
  methods: {
    onChangeSearchInput(evt) {
      this.strSearch = evt.target.value.toLowerCase();
      this.filterElems();
    },
    filterElems() {
      this.rowsFilteredBySearch = this.table.rows.filter(
        r => r.elems.packName.toLowerCase().includes(this.strSearch) === true
      );
    },
    //when click on next button of pagination
    onClickNext() {
      this.page++;
    },
    //when click on prev button of pagination
    onClickPrev() {
      this.page--;
    },
    //when click on delete button of actions elem
    onClickDelete(itemR, popID) {
      //using v-for, vue create an array, so you need to add [0] to access hide() method of popupREF
      this.$refs[popID][0].hide();
      this.$emit("requestDeleteHistoryEntry", itemR);
    },
    onClickUseThisSelection(itemR, popID) {
      //using v-for, vue create an array, so you need to add [0] to access hide() method of popupREF
      this.$refs[popID][0].hide();
      //find elem by ID
      const row = this.table.rows.find(_ => _.options.id === itemR.options.id);
      this.$emit("clickUseThisSelection", row);
    },
    //convert a row of table to real index of elems
    rowToIdx(iR = 0) {
      return this.page === 0 ? iR : this.rowsPerPage * this.page + iR;
    },
    updateHistory(dpState = []) {
      this.table.rows = [];
      dpState.forEach(entry => {
        this.createTableRow({
          exported: Number(entry.progress) === 100 && !entry.availableToDownload,
          workerID: entry.workerID,
          id: entry._id,
          packName: entry.directory_name,
          format: entry.file_format,
          eui: entry.eui,
          dateRange: [entry.start_date, entry.end_date],
          dataExport: entry.download_timestamp,
          done: entry.done,
          availableToDownload: entry.availableToDownload,
          dataRequest: entry.request_timestamp,
          progress: entry.progress
        });
      });
    },
    euiToSensor(eui) {
      return this.sensors.filter(s => eui.includes(s.eui));
    },
    createTableRow({
      exported = false,
      packName = "",
      format = "",
      eui = [],
      dateRange = [],
      id = "",
      done = false,
      dataExport = "",
      workerID = "",
      availableToDownload = false,
      dataRequest = "",
      progress = 0
    } = {}) {

      console.log("exported", exported)

      //sensors
      const sensors = this.euiToSensor(eui);
      console.log("dataExport", dataExport)
      //data_export
      const data_export = dates.toDateStringFormat(dataRequest, {
        divider: "/"
      });

      const state = Number(progress).toFixed(1)

      //typo_device
      const typo_device = [...new Set(sensors.map(_ => _.type))].join(", ");

      const sensor_eui = eui;

      //data_time_interval
      const data_time_interval = dateRange
        .map(d => dates.toDateStringFormat(d, { divider: "/" }))
        .join(" - ");

      const row = {
        options: {
          selected: false,
          id: id,
          sensorsEui: sensor_eui,
          dateRange: dateRange,
          workerID: workerID,
          availableToDownload: availableToDownload,
          done: done,
          dataRequest: dataRequest,
          exported: exported
        },
        elems: {
          packName: packName,
          dataExport: data_export,
          typoDevice: typo_device,
          deviceNumber: eui.length,
          dataTimeInterval: data_time_interval,
          format: format,
          state: state
        }
      };
      this.table.rows.push(row);
    }
  }
};
</script>

<style scoped>
.dataTimeIntervalContainer {
  max-width: 15vw;
}

.typoDeviceContainer {
  max-width: 15vw;
}

.packNameContainer {
  min-width: 200px;
  max-width: 20vw;
}

.progress-bar {
  width: 10vw;
}

.main-container {
  display: flex;
  flex-direction: column;
}
.header-actions {
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.text-alignment {
  text-align: left;
}
.tbody-tr.active {
  background: var(--table-row-background-color-active);
}
.tbody-tr:hover {
  background: var(--table-row-background-color-hover);
}
.tbody-tr.active:hover {
  background: var(--table-row-background-color-active);
}

.th-container {
  padding: 2px;
}
.td-container {
  padding: 2px;
  overflow-x: auto;
}
.popover-icon {
  margin-right: 12px;
  height: 14px;
  width: 14px;
}
.popover-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.popover-item {
  text-align: left;
}

.actions-container {
  margin-left: auto;
}
.state-content {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
.item-alignment {
  text-align: left;
}
.table-container {
  overflow-x: auto;
  height: 500px;
}
.pagination {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 12px 0px;
}
.page-indicator {
  margin-right: 12px;
}
.progrss-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table {
  border-collapse: collapse;
  width: 100%;
  min-width: 800px;
}

.table td {
  border-bottom: 1px solid var(--table-row-border-color);
  padding: 8px;
}

.table th {
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--table-header-background-color);
}
</style>
