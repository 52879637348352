<i18n>
{
  "en": {
    "deviceType": "Device Type",
    "device": {
      "vibrometer": "vibrometer",
      "tiltmeter": "tiltmeter",
      "deck": "deck",
      "accelerometer": "accelerometer"
    }
  },
  "it": {
    "deviceType": "Tipo",
    "device": {
      "vibrometer": "vibrometro",
      "tiltmeter": "inclinometro",
      "deck": "deck",
      "accelerometer": "accelerometro"
    }
  }
}
</i18n>

<template>
  <div class="devices-container">
    <header>
      <move-text>{{ $t("deviceType").toUpperCase() }}</move-text>
    </header>
    <div class="item-container scroll">
      <move-list-group>
        <move-list-item
          v-for="(item, index) in typoDevices"
          @click="onClickTypoDevice(item)"
          :key="index"
          :active="typoDevicesSelected.includes(item)"
          backgroundColor="var(--list-item-bg-color-secondary)"
          activeBackgroundColor="var(--list-item-bg-color-primary)"
        >
          <div class="item-content">
            <move-circle-chip backgroundColor="var(--primary-color-dark)">
              <move-text monospace>{{
                numberOfSensorsSelected(item)
              }}</move-text>
            </move-circle-chip>
            <move-text> {{ $t(`device.${item}`) }}</move-text>
          </div>
          <move-text
            ><font-awesome-icon icon="fa-solid fa-chevron-right"
          /></move-text>
        </move-list-item>
      </move-list-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "TypoDevices",
  props: {
    sensors: Array,
    sensorsSelected: Array
  },
  data() {
    return {
      typoDevicesSelected: []
    };
  },
  computed: {
    typoDevices() {
      return this.sensorsToTypo(this.sensors);
    }
  },
  watch: {
    typoDevicesSelected: {
      deep: true,
      handler: function() {
        this.$emit("change", this.typoDevicesSelected);
      }
    }
  },
  methods: {
    sensorsToTypo(sensors) {
      return [...new Set(sensors.map(_ => _.type))];
    },
    onClickTypoDevice(item) {
      const idx = this.typoDevicesSelected.indexOf(item);
      idx >= 0
        ? this.typoDevicesSelected.splice(idx, 1)
        : this.typoDevicesSelected.push(item);
    },
    numberOfSensorsSelected(item) {
      return this.sensorsSelected.filter(s => s.type === item).length;
    },
  }
};
</script>

<style scoped>


.item-content {
  display: flex;
  align-items: center;
}

.item-container {
  margin-top: 12px;
  overflow-y: auto;
}

.img-button {
  padding: 8px;
  width: 50px;
}
</style>
