<i18n>
{
  "en": {
    "sensors": "Sensors",
    "checkboxSensorsHeaderSelect": "Select all",
    "checkboxSensorsHeaderDeselect": "Deselect all",
    "searchInputPlaceHolder": "Search",
    "device": {
      "vibrometer": "vibrometer",
      "tiltmeter": "tiltmeter",
      "deck": "deck",
      "accelerometer": "accelerometer"
    }
  },
  "it": {
    "sensors": "Sensori",
    "checkboxSensorsHeaderSelect": "Seleziona Tutti",
    "checkboxSensorsHeaderDeselect": "Deseleziona Tutti",
    "searchInputPlaceHolder": "Ricerca",
    "device": {
      "vibrometer": "vibrometro",
      "tiltmeter": "inclinometro",
      "deck": "deck",
      "accelerometer": "accelerometro"
    }
  }
}
</i18n>

<template>
    <div class="sensors-container">
      <header>
        <MoveText>{{ $t("sensors").toUpperCase() }}</MoveText>
      </header>
      <EmptySensor v-if="!typoDevicesSelected.length" />
      <div v-else class="sensors-content">
        <div class="actions-container">
          <move-checkbox
            @click="onCheckboxSensorsHeader"
            :checked="allSensorsFilteredSelected"
            ><move-text noWrap h2>{{
              allSensorsFilteredSelected
                ? $t("checkboxSensorsHeaderDeselect")
                : $t("checkboxSensorsHeaderSelect")
            }}</move-text>
          </move-checkbox>
          <div class="search-container">
            <move-input
              @input="onChangeSearchInput"
              :placeholder="$t('searchInputPlaceHolder')"
            >
              <font-awesome-icon type="text" icon="fa-magnifying-glass" />
            </move-input>
          </div>
        </div>
        <div class="item-container scroll">
          <div
            v-for="(sensors, indexP) in sensorsFilteredGroupedByTypo"
            :key="indexP"
            class="group-class"
          >
            <move-list-group>
              <move-list-item
                v-for="(item, indexC) in sensors"
                :key="indexC"
                @click="onClickSensor(item)"
                :active="sensorsSelected.includes(item)"
                backgroundColor="var(--list-item-bg-color-secondary)"
                activeBackgroundColor="var(--list-item-bg-color-primary)"
              >
                <MoveText
                  >{{formatSensorName(item)}}</MoveText
                >
              </move-list-item>
            </move-list-group>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import EmptySensor from "./EmptySensors.vue"
export default {
  name: "SensorsList",
  components: {EmptySensor},
  props: {
    sensors: Array,
    typoDevicesSelected: Array
  },
  data() {
    return {
      sensorsFilteredBySearch: [],
      sensorsSelected: []
    };
  },
  computed: {
    sensorsFiltered() {
      //filter by selected typo device
      let sensorsFiltered = this.sensors.filter(s =>
        this.typoDevicesSelected.includes(s.type)
      );
      //filter by search
      sensorsFiltered = sensorsFiltered.filter(
        s =>
          !this.sensorsFilteredBySearch.length ||
          this.sensorsFilteredBySearch.includes(s)
      );
      return sensorsFiltered;
    },
    sensorsFilteredGroupedByTypo() {
      const typoSensors = this.sensorsToTypo(this.sensorsFiltered);
      return typoSensors.map(t =>
        this.sensorsFiltered.filter(_ => _.type === t)
      );
    },
    allSensorsFilteredSelected() {
      return this.sensorsFiltered.every(
        s => this.sensorsSelected.find(_ => _.eui === s.eui) !== undefined
      );
    }
  },
  watch: {
    sensorsSelected: {
      deep: true,
      handler: function() {
        this.$emit("change", this.sensorsSelected);
      }
    }
  },
  methods: {
    sensorsToTypo(sensors) {
      return [...new Set(sensors.map(_ => _.type))];
    },
    formatSensorName(s) {
      return `${this.$t(`device.${s.type}`)}-${s.eui.substring(s.eui.length - 6, s.eui.length - 1)}`;
    },
    onClickSensor(item) {
      const idx = this.sensorsSelected.indexOf(item);
      idx >= 0
        ? this.sensorsSelected.splice(idx, 1)
        : this.sensorsSelected.push(item);
    },
    onChangeSearchInput(e) {
      const v = e.target.value.toLowerCase();
      this.sensorsFilteredBySearch = this.sensors.filter(s =>
        this.formatSensorName(s).includes(v)
      );
    },
    onCheckboxSensorsHeader(evt) {
      this.sensorsSelected = evt.target.checked ? this.sensorsFiltered : [];
    }
  }
};
</script>

<style scoped>
.group-class {
  /* margin-top: 16px; */
  margin-bottom: 24px;
  /* padding-bottom: 12px; */
  margin-right: 8px;
  /* border-bottom: 1px solid var(--divider-color); */
}

.sensors-container >>> header {
  flex: none;
}

.sensors-content {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.search-container {
  min-width: 100px;
  display: flex;
  margin-right: 8px;
}

.devices-container {
  padding-right: 24px;
}
.sensors-container {
  /* border-left: var(--divider); */
  /* padding-left: 32px; */
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.item-container {
  margin-top: 12px;
  overflow-y: auto;
}

.img-button {
  padding: 8px;
  width: 50px;
}
</style>
