<template>
  <div class="global scroll">
    <div class="section">
      <Selection
        :sensors="sensors"
        :selectedRow="selectedRow"
        v-on="$listeners"
        :processOnGoing="processOnGoing"
      />
    </div>
    <div class="section">
      <History
        ref="dpHistory"
        @clickUseThisSelection="onClickUseThisSelection"
        v-on="$listeners"
        :sensors="sensors"
        :dpState="dpState"
        :processOnGoing="processOnGoing"
      />
    </div>
  </div>
</template>

<script>
import Selection from "./components/Selection.vue";
import History from "./components/History.vue";
export default {
  name: "DPSection",
  components: {
    Selection,
    History,
  },
  props:{
    sensors: Array,
    dpState: Array,
    processOnGoing: Boolean
  },
  data(){
    return{
      selectedRow:{}
    }
  },
  methods: {
    onClickUseThisSelection(row){
      this.selectedRow = row;
    }
  },
};
</script>

<style scoped>
.global {
  padding: 0px 2%;
}
.section {
  margin-bottom: 32px;
}
</style>
