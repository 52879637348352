<template>
  <div>
    <PageTemplate :hideTips="true">
      <template v-slot:nav>
        <DateRangeSelect
          @structure-selected="selectStructure"
          :selectedStructure="getStructureDetails"
          :availableStructures="getStructures"
        />
      </template>
      <template v-slot:content>
        <DPSection
          :sensors="getSensors"
          :dpState="dpState"
          :processOnGoing="processOnGoing"
          @requestDeleteHistoryEntry="onRequestDeleteHistoryEntry"
          @submitExportData="onSubmitExportData"
        />
      </template>
    </PageTemplate>
  </div>
</template>
<script>
import { DpService } from "../../services/dpService.js";
import { mapGetters, mapActions } from "vuex";
import PageTemplate from "../PageTemplate.vue";
import DPSection from "../../components/downloadPage/DPSection.vue";
import DateRangeSelect from "../../components/ui/DRSelect.vue";

export default {
  name: "Download",
  components: {
    PageTemplate,
    DPSection,
    DateRangeSelect
  },
  data() {
    return {
      dpState: [],
      dpService: undefined,
      processOnGoing: false
    };
  },
  computed: {
    ...mapGetters({
      getStructures: "structure/getStructures",
      getSensors: "structure/getSensors",
      getSelectedStructure: "structure/getSelectedStructure",
      getStructureDetails: "structure/getStructureDetails",
      getUserType: "user/getUserType"
    }),
  },
  watch: {
    getSelectedStructure: {
      deep: true,
      handler: function(newStructure) {
        this.onChangeStructure(newStructure._id)
      }
    }
  },
  methods: {
    ...mapActions({
      selectStructure: "structure/selectStructure"
    }),
    getToken() {
      return `bearer ${localStorage.getItem("token")}`;
    },
    getStructureId() {
      let structureId = undefined;
      if (this.getSelectedStructure && "_id" in this.getSelectedStructure) {
        structureId = this.getSelectedStructure._id;
      }
      return structureId;
    },

    async onRequestDeleteHistoryEntry(item) {
      console.log(item);
      await this.dpService.deleteFile(item.options.id)
    },

    async onSubmitExportData(
      data = {
        packName: "",
        format: "",
        sensors: [],
        dateRange: []
      }
    ) {
      console.log(data);
      if (this.dpService){
        this.dpService.structureID = this.getStructureId();
        this.dpService.requestFile({
          start_date: data.dateRange[0].toISOString(),
          end_date: data.dateRange[1].toISOString(),
          dir_name: data.packName,
          overwrite_history: true,
          eui_list: data.sensors,
          format: data.format
        });
      }
    },
    onChangeStructure(structureID){
      if (this.dpService) {
        this.dpService.structureID = structureID;
        this.dpService.requestState()
      }
    },
    onNewDpState(newState){
      console.log("update-state", newState);
      this.dpState = newState;
    },
    onTriggerProcessOnGoing(newState){
      console.log("process-ongoing", newState);
      this.processOnGoing = true;
    },
    onTriggerProcessCompleted(newState){
      console.log("process-completed", newState);
      this.processOnGoing = false;
    }
  },


  async mounted() {
    if (!this.dpService) {
      this.dpService = new DpService();
    }

    this.dpService.structureID = this.getStructureId();

    this.dpService.on("update-state", this.onNewDpState);
    this.dpService.on("process-ongoing", this.onTriggerProcessOnGoing);
    this.dpService.on("process-completed", this.onTriggerProcessCompleted);

    await this.dpService.start();
  },
  beforeDestroy(){
    this.dpService.off(this.onNewDpState)
    this.dpService.off(this.onTriggerProcessOnGoing)
    this.dpService.off(this.onTriggerProcessCompleted)
    this.dpService.cleanUp();
    console.log("clean up")
  }
};
</script>
<style scoped></style>
