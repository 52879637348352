<template>
  <div class="main-container">
    <TypoDevices :sensors="sensors" :sensorsSelected="sensorsSelected"  @change="onChangeTypoDevices" />
    <move-vertical-divider lineStyle="2px solid var(--divider-color)"></move-vertical-divider>
    <SensorsList :sensors="sensors" :typoDevicesSelected="typoDevicesSelected" @change="onChangeSensors" />
  </div>
</template>

<script>
import TypoDevices from "./sensors-selection/TypoDevices.vue"
import SensorsList from "./sensors-selection/SensorsList.vue"
export default {
  name: "SensorsSelection",
  components: {TypoDevices, SensorsList},
  props: {
    sensors: Array
  },
  data(){
    return{
      sensorsSelected:[],
      typoDevicesSelected:[]
    }
  },
  methods:{
    onChangeTypoDevices(devices){
      this.typoDevicesSelected = devices
    },
    onChangeSensors(sensors){
      this.sensorsSelected = sensors;
      this.$emit("changeSensors", sensors)
    }
  }
}
</script>

<style scoped>
.main-container {
  display: grid;
  grid-template-columns: minmax(100px, 6fr) minmax(1px, 1px) minmax(100px, 8fr);
  column-gap: 24px;
  height: 100%;
  margin-bottom: 8px;
}
</style>