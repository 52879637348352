const api = require("./api");
const { EventEmitter } = require("events");

class DpService extends EventEmitter {
  constructor() {
    super();
    this.pollingIID = undefined;
    this.pollingTime = 5000;
    this.structureID = undefined;
    this.state = [];

    this.emitNewState = this.emitNewState.bind(this)
    this.emitProcessOnGoing = this.emitProcessOnGoing.bind(this)
    this.emitProcessCompleted = this.emitProcessCompleted.bind(this)
    this.apiDpGetState = this.apiDpGetState.bind(this)
    this.apiDownloadFile = this.apiDownloadFile.bind(this)

    this.getStateAndEmit = this.getStateAndEmit.bind(this)
    this.fileToDownload = this.fileToDownload.bind(this)
    this.filesPending = this.filesPending.bind(this)
    this.checkForExitPolling = this.checkForExitPolling.bind(this)
    this.cleanUp = this.cleanUp.bind(this)
    this.downloadFile = this.downloadFile.bind(this)

    this.onPolling = this.onPolling.bind(this)
    this.checkStateWithPolling = this.checkStateWithPolling.bind(this)
    this.completeFiles = this.completeFiles.bind(this)
    this.requestState = this.requestState.bind(this)
    this.deleteFile = this.deleteFile.bind(this)
    this.requestFile = this.requestFile.bind(this)
    this.start = this.start.bind(this)
  }

  get token() {
    return `bearer ${localStorage.getItem("token")}`;
  }

  /* Events */
  emitNewState() {
    this.emit("update-state", this.state);
  }
  emitProcessOnGoing() {
    this.emit("process-ongoing", this.state);
  }
  emitProcessCompleted() {
    this.emit("process-completed", this.state);
  }

  /* API */
  async apiDpGetState() {
    console.log("this.token", this.token);
    console.log("structure", this.structureID);
    let r = [];
    if (!this.structureID) {
      return r;
    }
    const response = await api.dpGetState(this.token, {
      structure: this.structureID
    });
    r = response.status === 200 ? response.data : [];
    return r;
  }
  async apiDownloadFile(url, cookie) {
    
    console.log("url", url)
    console.log("cookie", cookie)
    return await api.dpDownloadFile(url, {
      cookie: cookie
    });

  }
  async apiGetFileUrl(file){
    const r = {
      url: undefined,
      token: undefined
    }
    const response = await api.getFileUrl(this.token, {
      workerID: file.workerID
    });
    if (response.status === 200) {
      r.url = response.data.url;
      r.token = response.data.token;
      r.cookie = response.headers["Set-Cookie"];
    }
    console.log("apiGetFileUrl", response)
    return r;
  }

  async getStateAndEmit() {
    this.state = await this.apiDpGetState();
    this.emitNewState();
  }
  fileToDownload() {
    return this.state.find(_ => _.availableToDownload === true);
  }
  filesPending() {
    return this.state.filter(_ => _.progress < 100);
  }
  checkForExitPolling() {
    return this.fileToDownload() || !this.filesPending().length
      ? true
      : false;
  }
  cleanUp() {
    if (this.pollingIID) {
      clearInterval(this.pollingIID);
    }
    this.pollingIID = undefined;
  }
  async downloadFile(file) {
    let response = undefined
    response = await this.apiGetFileUrl(file);
    console.log("response of get file url", response)
    if (!response) {
      return;
    }

    document.cookie = response.cookie;
    console.log("document.cookie", document.cookie)

    response = await this.apiDownloadFile(response.url, response.token);

    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    let fileLink = document.createElement("a");

    fileLink.href = fileURL;
    const fileName = `${file.directory_name}.zip`;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);

    fileLink.click();
  }
  async onPolling() {
    
    await this.getStateAndEmit();
    if (this.checkForExitPolling()) {
      if (this.fileToDownload())
        await this.downloadFile(this.fileToDownload());
        await this.getStateAndEmit();
        this.cleanUp();
        this.emitProcessCompleted();
    }
  }

  checkStateWithPolling() {
    this.cleanUp();
    this.emitProcessOnGoing();
    this.pollingIID = setInterval(this.onPolling, this.pollingTime);
  }

  async completeFiles() {
    if (this.fileToDownload()) {
      await this.downloadFile(this.fileToDownload());
    }
    if (this.filesPending().length) {
      this.checkStateWithPolling();
    }
  }
  async requestState() {
    await this.getStateAndEmit();
  }
  async deleteFile(id) {
    try {
      await api.dpDeleteItem(this.token, { _id: id });
      await this.getStateAndEmit();
      this.cleanUp();
    } catch (e) {
      console.log(e);
    }
  }
  async requestFile(
    data = {
      start_date: "",
      end_date: "",
      dir_name: "",
      overwrite_history: true,
      eui_list: [],
      format: ""
    }
  ) {
    console.log(data);
    await api.dpRequestFile(
      this.token,
      {
        start_date: data.start_date,
        end_date: data.end_date,
        dir_name: data.dir_name,
        overwrite_history: data.overwrite_history,
        eui_list: data.eui_list,
        structure: this.structureID
      },
      { format: data.format }
    );
    await this.getStateAndEmit();
    await this.completeFiles();
  }
  async start() {
    this.cleanUp();
    await this.getStateAndEmit();
    await this.completeFiles();
  }
}

module.exports = {
  DpService
};
